// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-case-studies-js": () => import("./../../../src/templates/about/case-studies.js" /* webpackChunkName: "component---src-templates-about-case-studies-js" */),
  "component---src-templates-about-case-study-js": () => import("./../../../src/templates/about/case-study.js" /* webpackChunkName: "component---src-templates-about-case-study-js" */),
  "component---src-templates-about-faqs-js": () => import("./../../../src/templates/about/faqs.js" /* webpackChunkName: "component---src-templates-about-faqs-js" */),
  "component---src-templates-about-for-me-js": () => import("./../../../src/templates/about/for-me.js" /* webpackChunkName: "component---src-templates-about-for-me-js" */),
  "component---src-templates-about-for-me-slug-js": () => import("./../../../src/templates/about/for-me-slug.js" /* webpackChunkName: "component---src-templates-about-for-me-slug-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/templates/about/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-about-team-js": () => import("./../../../src/templates/about/team.js" /* webpackChunkName: "component---src-templates-about-team-js" */),
  "component---src-templates-all-conditions-js": () => import("./../../../src/templates/all-conditions.js" /* webpackChunkName: "component---src-templates-all-conditions-js" */),
  "component---src-templates-all-treatments-js": () => import("./../../../src/templates/all-treatments.js" /* webpackChunkName: "component---src-templates-all-treatments-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-slug-js": () => import("./../../../src/templates/blog/slug.js" /* webpackChunkName: "component---src-templates-blog-slug-js" */),
  "component---src-templates-chiropractor-js": () => import("./../../../src/templates/chiropractor.js" /* webpackChunkName: "component---src-templates-chiropractor-js" */),
  "component---src-templates-condition-js": () => import("./../../../src/templates/condition.js" /* webpackChunkName: "component---src-templates-condition-js" */),
  "component---src-templates-condition-low-back-pain-js": () => import("./../../../src/templates/condition-low-back-pain.js" /* webpackChunkName: "component---src-templates-condition-low-back-pain-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-exercises-index-js": () => import("./../../../src/templates/exercises/index.js" /* webpackChunkName: "component---src-templates-exercises-index-js" */),
  "component---src-templates-exercises-slug-js": () => import("./../../../src/templates/exercises/slug.js" /* webpackChunkName: "component---src-templates-exercises-slug-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-treatment-js": () => import("./../../../src/templates/treatment.js" /* webpackChunkName: "component---src-templates-treatment-js" */),
  "component---src-templates-what-to-expect-js": () => import("./../../../src/templates/what-to-expect.js" /* webpackChunkName: "component---src-templates-what-to-expect-js" */)
}

